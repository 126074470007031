export const MenuList=[

    {
        title: "What We Do",
        url:"/whatwedo"
    },
    {
        title: "About Us",
        url:"/teams"
    },
    {
        title: "Privacy",
        url:"/privacy"
    },
    {
        title: "Careers",
        url:"/careers"
    },
]