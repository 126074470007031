import React from "react";
import "./Solution.css";
import solution3 from "../../images/solutions3.png";
import MainSolution from "./MainSolution";
import Footer from "../Footer/Footer";

const Symbio = () => {
  return (
    <>
    <MainSolution/>
      <div className="solution-feature container">
        <span>Symbio</span>
        <h1>UNLOCK MARKETING & MEDIA OPPORTUNITIES WITH VENDOR PARTNERS</h1>
        <p>
          SYMBIO is a partner-funding marketing platform that unlocks new
          marketing opportunities between companies and their vendor partners.
          SYMBIO acts as a Data Switzerland that allows partners to seamlessly
          purchase exclusive marketing programs, thereby creating a new
          monetization platform for the primary company without adding costs.
        </p>

        <div className="solution-features-Benfites">
          <h1>FEATURES + BENEFITS</h1>

          <div className="features-main">
            <div className="features-left">
              <h2>Triple V Framework</h2>
              <p>
                We focus on increasing value, velocity, and volume. We will
                improve the amount each partner spends per marketing program,
                increase the frequency of spend, and add more vendor partners
                over time.
              </p>
              <h2>End-to-End Execution</h2>
              <p>
                We work directly with both the primary company and their vendor
                partners to design campaigns, identify high propensity
                audiences, execute campaigns, and report results.
              </p>
            </div>

            <div className="features-mid">
              <img src={solution3} alt="Symbio" />
            </div>

            <div className="features-end">
              <h2>Partner Portal</h2>
              <p>
                Customized portals enable vendor partners to seamlessly purchase
                marketing opportunities, view performance results, and interact
                directly with the SYMBIO team.
              </p>
              <h2>Exclusive Insights and Reporting</h2>
              <p>
                To reinforce the value of the investments for the vendor
                partners, we provide exclusive end-customer insights without
                transferring any first party data to the vendor partners. We
                also work with vendor partners directly to develop business
                cases for incremental investments.
              </p>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
    </>
  );
};

export default Symbio;
