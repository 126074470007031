import React from "react";
import "../Styles/Privacy.css";
import privacy from "../images/Privacy_policy.svg";
import Footer from "../components/Footer/Footer";

const Privacy = () => {
  return (
    <>
      <div className="privacy-banner">
        <div className="privacy-banner-txt container">
          <div className="privacy-banner-left">
            <h1>Privacy Policy</h1>
            <p>
              This Privacy Policy for Bridgetree LLC defines Bridgetree’s
              commitment to privacy. This policy has been adopted to keep all
              data safe, secure, and private and to comply with federal and
              state privacy laws. The following rights are applicable to all
              users:
            </p>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              className="privacy-banner-right"
              src={privacy}
              alt="Privacy_Policy"
            />
          </div>
        </div>
      </div>
      <div className="privacy-main container">
        <div>
          <h1 className="privacy-main-heading">User Rights</h1>
          <p className="privacy-main-policy" style={{ paddingBottom: 1 }}>
            Right to Know: This is your right to know what categories of
            personal information we collect about you, how we use your personal
            information, whether we share, disclose and/or sell your personal
            information to third parties, and what other rights you may have.
          </p>
          <ul>
            <li className="privacy-main-policy">
              Right to Delete: This is your right to request that we delete the
              personal information we have collected from you and maintain in
              our systems, subject to certain exceptions that permit us to keep
              your personal information for specific purposes.
            </li>
            <li className="privacy-main-policy">
              Right to Access: This is your right to request access to the
              personal information we have collected about you.{" "}
            </li>
            <li className="privacy-main-policy">
              {" "}
              Right to Opt-Out of Sale: This right allows you to opt-out of the
              sale of your personal information to third parties.{" "}
            </li>
            <li className="privacy-main-policy">
              Right to Non-Discrimination/Equal Service: This right means that
              Bridgetree will not discriminate against you in any manner because
              you elect to exercise any of your rights.
            </li>
          </ul>
        </div>

        <div>
          <h1 className="privacy-main-heading">Information We Collect</h1>
          <p className="privacy-main-policy" style={{ paddingBottom: 1 }}>
            Bridgetree has collected the following categories of personal
            information:
          </p>
          <ul>
            <li className="privacy-main-policy">
              Personal identifiers (e.g., real name, alias, postal address,
              unique personal identifier, online identifier, Internet Protocol
              address, email address, or other similar identifiers);
            </li>
            <li className="privacy-main-policy">
              Personal information categories (e.g., name, address, telephone
              number, education, employment, publicly available financial
              information, medical information, or health insurance
              information);{" "}
            </li>
            <li className="privacy-main-policy">
              Commercial information (e.g., records of personal property,
              products or services purchased, obtained, or considered, or other
              purchasing or consuming histories or tendencies);
            </li>
          </ul>
          <p className="privacy-main-policy" style={{ paddingBottom: 1 }}>
            Bridgetree does not consider the following personal information:{" "}
          </p>
          <ul>
            <li className="privacy-main-policy">
              Publicly available information from government records;{" "}
            </li>
            <li className="privacy-main-policy">
              Deidentified or aggregated consumer information;{" "}
            </li>
            <li className="privacy-main-policy">
              {" "}
              Health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data;
            </li>
            <li className="privacy-main-policy">
              Personal information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver’s Privacy Protection Act of
              1994.
            </li>
          </ul>
        </div>

        <div>
          <h1 className="privacy-main-heading">
            Sources of Personal Information
          </h1>
          <p className="privacy-main-policy" style={{ paddingBottom: 1 }}>
            Bridgetree collects personal information about you from the
            following sources:{" "}
          </p>
          <ul>
            <li className="privacy-main-policy">
              {" "}
              Directly from our clients;{" "}
            </li>
            <li className="privacy-main-policy">
              {" "}
              Information obtained from third parties; and{" "}
            </li>
            <li className="privacy-main-policy">
              {" "}
              Publicly available information.{" "}
            </li>
          </ul>
        </div>

        <div>
          <h1 className="privacy-main-heading">Use of Personal Information</h1>
          <p className="privacy-main-policy" style={{ paddingBottom: 1 }}>
            We may use this personal information to operate, manage, and
            maintain our business, to provide our products and services, to
            communicate with you, for our vendor management purposes, and to
            accomplish our business purposes and objectives, including, for
            example, using personal information to: develop, improve, repair,
            and maintain our products and services; process or fulfill a request
            or other transactions submitted to us; personalize, advertise, and
            market our products and services; conduct research, analytics, and
            data analysis; maintain our facilities and infrastructure; undertake
            quality and safety assurance measures; conduct risk and security
            control and monitoring; detect and prevent fraud; perform identity
            verification; perform accounting, audit, and other internal
            functions; comply with law, legal process, and internal policies;
            maintain records; exercise and defend legal claims; and fulfill
            legal obligations.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
