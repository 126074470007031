import React,{useEffect} from 'react';
import { ReactComponent as BtLogo } from "../../images/bt_Footer.svg";
import {MenuList} from '../../NavBar/MenuList';
import "./Footer.css";
import "../../Styles/NavBar.css"
import { useNavigate } from "react-router";
import { Button } from '@mui/material';



const Footer = () => {

 
    
    const navigate = useNavigate();
    const menuList= MenuList.map(({url,title})=>{
        return(
            <li >
                <a onClick={()=>navigate(url)}>{title}</a>
            </li>
        );
    });

    useEffect(() => {
        window.scrollTo({top:0, left:0, behavior:"smooth"})
    
      
    }, [navigate])
    

  return (
    <>
    <div className='Footer-main'>
    <div className='Footer container'>
        <span className='Footer-left'>
            <BtLogo className='Footer-logo' onClick={()=>{navigate('/')} }/>
            
        </span>
        <ul className='Footer-mid'>
            {menuList}
        </ul>
        <div className='Footer-end'>
           
            <div style={{right: 0}}>
            <Button  className='connect'><a style={{margin: 0, color: "#FFFFFF"}} onClick={()=>navigate('/connect')} >Let's Connect</a></Button>
            </div>    
        </div>
      
    </div>
    <span className='copyright-span'>Copyright ©{(new Date().getFullYear())} Bridgetree. All rights reserved.</span>
    </div>
    
    </>
  )
}

export default Footer