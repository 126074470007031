import React from "react";
import "../components/About/AboutUs.css";
import culture from "../images/Group.svg";
import privacy from "../images/Privacy.svg";
import Commitment from "../images/Commitment.svg";
import data from "../images/Data.svg"
import About from '../components/About/About'
import Footer from "../components/Footer/Footer";


const WhoWeAre = () => {
  return (
    <>
   <About/>

      <div className="who-we-are container">
        <h1>Who We Are</h1>

        <div style={{ display: "flex", flexDirection: "column",paddingTop:20,paddingBottom:30 }}>
          <div className="about-details-1">
            <div className="details-left">
              <div>
                <h2>Culture</h2>
                <p>
                  Bridgetree’s culture is one that emphasizes innovation,
                  quality, accuracy, respect and honesty. We have many repeat
                  clients due to the partnerships we develop and nurture. We
                  have an objective approach, which allows us to be academic
                  with our questions and non-biased with our conclusions.We
                  emphasize substance over “flash,” and we spend our energy
                  focusing on our customers’ businesses. We benefit only if our
                  customers do first.The company is organized to operate with
                  speed and agility. We hire the best talent, treat each other
                  with respect, and encourage innovative thinking and
                  independent action on behalf of our clients.
                </p>
              </div>
            </div>
            <div>
              <img className="details-right" src={culture} />
            </div>
          </div>

          <div className="about-details-2">
            <div>
              <img className="details-img-privacy" src={privacy} />
            </div>
            <div className="details-left">
              <div>
                <h2>We Value your Privacy</h2>
                <p>
                  To protect our clients, we have a policy of not publicly
                  disclosing any names, applications, web sites, data center
                  locations, data types, sources, integration points,
                  certifications or any other information that can cause harm if
                  compromised. Our security posture is aggressive and our
                  privacy policy starts with silence.
                </p>
              </div>
            </div>
          </div>

          <div className="about-details-1">
            <div className="details-left">
              <div>
                <h2>Our Commitment to you</h2>
                <p>
                  Honest, unbiased and experienced guidance. We know our
                  customers have a choice in the partners they choose, which is
                  why we are personally invested in every engagement. We are
                  committed to performing at the highest level by setting
                  ambitious goals and delivering optimal results in a timely
                  manner. We maintain the highest ethical standards to protect
                  our customers and invest authority in our employees.
                </p>
              </div>
            </div>
            <div>
              <img className="details-img-Commitment" src={Commitment} />
            </div>
          </div>

          <div className="about-details-2">
            <div>
              <img className="details-img-privacy" src={data} />
            </div>
            <div className="details-left">
              <div>
                <h2>Data Switzerland</h2>
                <p>
                Nearly all of our programs are multichannel — and Bridgetree constantly serves as the integration hub between data, web, mail, text, call, mobile, general media and partners. Our partnership and integration capabilities are unusual and based on a business practice we call “objectivity.” Objectivity gives us the credibility and trust needed — with clients, client suppliers and partners — to be a valued data services partner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </>
  );
};

export default WhoWeAre;
