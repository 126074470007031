import React from "react";
import "./Solution.css";
import solution2 from "../../images/solutions2.png";
import MainSolution from "./MainSolution";
import Footer from "../Footer/Footer";

const Idea = () => {
  return (
    <>
     <MainSolution/>
      <div className="solution-feature container">
        <span>Idea</span>
        <h1>DRIVE INNOVATION</h1>
        <p>Our Innovation Design and Execution Accelerator (“IDEA”) delivers new, scalable market tests that rapidly unlock growth opportunities. Our agile team focuses exclusively on the design and execution of test-and-learn opportunities. We work as an innovation lab that supports in-house or partner CRM teams. Once our test learnings are validated in-market, we transition those learnings to the core marketing teams to incorporate into larger marketing programs.</p>

        <div className="solution-features-Benfites">
          <h1>FEATURES + BENEFITS</h1>

          <div className="features-main">
            <div className="features-left">
              <h2>Agile Innovation Team</h2>
              <p>A nimble team of marketing strategists, data scientists, application developers, and campaign operators will design, execute and evaluate marketing tests.</p>
              <h2>Art-of-the-Possible Capabilities</h2>
              <p>We focus on ways to better reach and engage consumers, which means we need to think beyond channel campaigns. Our innovation team can develop next-generation tests, such as augmented reality or geolocation targeting.</p>
    
            </div>

            <div className="features-mid">
              <img src={solution2} alt="Idea" />
            </div>

            <div className="features-end">
              <h2>Speed-to-Learnings-to-Value</h2>
              <p>
              We apply minimum viable product (“MVP”) principles which result in more tests, faster results, and greater throughput to scale.
              </p>
              <h2>Performance Based Options</h2>
              <p>
              Our ultimate goal is to identify new ways to drive incremental marketing outcomes at scale. We are willing to construct commercial models where we only get paid for successful tests that deliver pre-defined results at scale.
              </p>
             
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Idea;
