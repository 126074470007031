import React from 'react'
import "./Solution.css";


const MainSolution = () => {
  return (
    <>
    <div className='solution-main container'>
       <h1>
         Solutions
       </h1>
       <h2>
         what we can do for you
       </h2>
   </div>
   </>
  )
}

export default MainSolution