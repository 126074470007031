import React from "react";
import img1 from "../images/marketing-strategy.png";
import img2 from "../images/data-intelligence.png";
import img3 from "../images/actionable-insights.png";
import img4 from "../images/marketing-technology.png";
import img5 from "../images/performance-measurement.png";
import img6 from "../images/omni.png";
import { ReactComponent as Bg_img } from "../images/BG _IMG.svg";
import result from "../images/Results.svg";
import marketers from "../images/marketersfirst.svg";
import speed from "../images/speedtovalue.svg";
import innovation from "../images/innovation.svg";
import "../Styles/Home.css";
import HomeSol from "../components/HomeSol/HomeSol";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
        <div className="first-part container">
          <div className="first-part-vr">
            <h1>what We Do</h1>
          </div>
        </div>

        <div className="card-1 container">
          <div className="inside-card">
            <h3>MARKETING STRATEGY</h3>
            <p>
              We help clients acquire new customers, develop lifetime value +
              retain customer relationships that last.{" "}
            </p>
            <img src={img1} />
            <p style={{ padding: 10 }}>
              We’re holistic—we see how all your offline and online pieces fit
              together to make sure you’re leveraging them for maximum
              efficiency.
            </p>
          </div>
          <div className="inside-card">
            <h3>DATA INTELLIGENCE</h3>
            <p>
              We manage first-party data on behalf of our clients and enrich
              customer records to create unique, Golden Records that fuel
              personalized marketing.{" "}
            </p>
            <img src={img2} />
            <p style={{ padding: 10 }}>
              We leverage data sources to fold in granular third and
              second-party audiences to go beyond the typical first party and
              lookalike targeting to help you find the segments that convert
              best.
            </p>
          </div>
          <div className="inside-card">
            <h3>ACTIONABLE INSIGHTS</h3>
            <p>
              We apply advanced analytics and utilize powerful artificial
              intelligence and machine learning to unlock new marketing
              opportunities that deliver lift.{" "}
            </p>
            <img src={img3} />
            <p style={{ padding: 10 }}>
              Yes, lots of data is great, but we don’t forget the human factor.
              We have data scientists and marketers on staff to help you make
              sense out of what all your data means and how to leverage it for
              better business outcomes.
            </p>
          </div>
          <div className="inside-card">
            <h3>MARKETING TECHNOLOGY</h3>
            <p>
              We design, orchestrate and execute personalized campaigns across
              any channel, within our clients’ tech stack or using our own, if
              needed.{" "}
            </p>
            <img src={img4} />
            <p style={{ padding: 10 }}>
              Our managed/campaign services team can execute campaigns in online
              areas like paid search, social and programmatic display as well as
              offline areas like direct mail.
            </p>
          </div>
          <div className="inside-card">
            <h3>PERFORMANCE MEASUREMENT</h3>
            <p>
              We provide closed-loop performance reporting and develop
              dashboards to visualize the efficacy of our clients’ marketing
              spend.{" "}
            </p>
            <img src={img5} />
            <p style={{ padding: 10 }}>
              You own your data. We are 100% transparent and provide all raw
              data from sources like website analytics, ad platforms and any
              data source we may manage.
            </p>
          </div>
          <div className="inside-card">
            <h3>OMNI CHANNEL EXECUTION</h3>
            <p>
              We implement open-garden platforms and custom technology solutions
              that enable effective consumer marketing at scale.{" "}
            </p>
            <img src={img6} />
            <p style={{ padding: 10 }}>
              Working in closed gardens like Facebook? Not a problem. Leveraging
              platforms with open APIs and web hooks? We can help them talk to
              one another. We have in-house developers, so no matter how custom
              your need, we can code to your specific business needs.
            </p>
          </div>
        </div>

        <div className="second-part container">
          <div className="second-part-vr">
            <h1>THE BRIDGETREE DIFFERENCE</h1>
          </div>
        </div>

        <div className="third-part container">
          <div className="third-part-inside">
            <div className="bt-diff">
              <h3>RESULTS</h3>
              <p>
                We focus exclusively on the outcomes that matter most to
                marketers: to profitably acquire, develop and retain customers.
                We are accountable for these outcomes and strive to structure
                engagements that are performance-based.
              </p>
              <h4>The average tenure of our top 8 clients is {">"} 8 yrs.</h4>
            </div>
            <div className="bt-diff-img">
              <img src={result} />
            </div>
          </div>

          <div className="third-part-inside-1 ">
            <div className="bt-diff-img-1">
              <img src={speed} />
            </div>
            <div className="bt-diff-1">
              <h3>SPEED TO VALUE</h3>
              <p>
                We believe that market-validated learnings are the critical
                component to unlock outcomes. We don’t let perfection be the
                enemy of good – from application development to campaign
                deployment. We over-invest early in client relationships to
                ensure that we succeed quickly.{" "}
              </p>
              <h4>
                We implemented a 1:1 CRM test-and-learn portfolio program in{" "}
                {"<"} 4 weeks.
              </h4>
            </div>
          </div>

          <div className="third-part-inside">
            <div className="bt-diff">
              <h3>MARKETERS FIRST</h3>
              <p>
                We have sat in the seat. Every conversation and engagement
                involves a team comprised of former client-side marketers. We
                contextualize your opportunities quickly and develop
                test-and-learn approaches that yield results.{" "}
              </p>
              <h4>
                We implemented a full marketing tech stack and delivered 10%
                lift in marketing outcomes {"<"} 6 weeks.{" "}
              </h4>
            </div>
            <div className="bt-diff-img">
              <img src={marketers} />
            </div>
          </div>

          <div className="third-part-inside-1">
            <div className="bt-diff-img-1">
              <img src={innovation} />
            </div>
            <div className="bt-diff-1">
              <h3>INNOVATION</h3>
              <p>
                We unlock innovation-as-a-competency. Our in-house research and
                innovation team works with clients to solve unique client
                problems – ranging from natural language processing bots to
                website scrapers to in-store digital transformation initiatives.{" "}
              </p>
              <h4>
                In 24 hours, we developed a customer reservation system to help
                retailers address COVID-19 public health issues.{" "}
              </h4>
            </div>
          </div>
        </div>
    
        <div className="second-part container"> </div>
      <Footer />
    </>
  );
};

export default Home;
