import React from "react";
import img1 from "../images/marketing-strategy.png";
import img2 from "../images/data-intelligence.png";
import img3 from "../images/actionable-insights.png";
import img4 from "../images/marketing-technology.png";
import img5 from "../images/performance-measurement.png";
import img6 from "../images/omni.png";
import { ReactComponent as Bg_img } from "../images/BG _IMG.svg";
import result from "../images/Results.svg";
import marketers from "../images/marketersfirst.svg";
import speed from "../images/speedtovalue.svg";
import innovation from "../images/innovation.svg";
import "../Styles/Home.css";
import HomeSol from "../components/HomeSol/HomeSol";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <>
      
        <div className="background-img">
          <div className="background-img-txt container">
            <div className="background-left">
              <p className="background-txt">
                WE DELIVER
                <br />
                OUTCOMES THAT MATTER
              </p>
              <br />
              <p className="background-para">
                Built on over 28 years of success for our clients, we build the bridges between data and customer engagement to quickly deliver outcomes that matter.
              </p>
              <br />
            </div>

            <div className="background-right">
              <Bg_img className="bt-right" />
            </div>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default Home;
