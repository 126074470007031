import { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    const script_1 = document.createElement('script');
    script_1.async = true;
    script_1.src = `https://www.googletagmanager.com/gtag/js?id=G-20W6B43LKL`;
    script_1.onload = () => {
      const script_2 = document.createElement('script');
      script_2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-20W6B43LKL');
      `;
      document.head.appendChild(script_2);
      console.log('Google Analytics scripts loaded'); // Confirm scripts are loaded
    };
    document.head.appendChild(script_1);
  }, []);
 };

export default GoogleAnalytics;