import React from "react";
import { ReactComponent as Acc1 } from "../../images/1.svg";
import { ReactComponent as Acc2 } from "../../images/2.svg";
import { ReactComponent as Acc3 } from "../../images/3.svg";
import { ReactComponent as Acc4 } from "../../images/4.svg";
import { ReactComponent as Acc5 } from "../../images/5.svg";
import Footer from "../Footer/Footer";

const AcceleratedSol = () => {
  return (
    <>
      <div className="solution-feature-acc container">
        <span>ACCELERATED SOLUTIONS</span>
        <h1>EXECUTE PROVEN SOLUTIONS FAST</h1>
        <div className="solution-features-Benfites">
          <div className="acc-features-main">
            <div className="acc-features-left">
              <h2>New Mover Marketing</h2>
              <p>
                Moving is an expensive life stage for consumers, and a lucrative
                event for marketers. Our proprietary new mover marketing
                platform utilizes a trade secret process to reach the right
                person at the right time across the right channel, and extends
                the marketing beyond a single point in time.
              </p>

              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "flex-wrap": "nowrap",
                  "align-content": "space-around",
                }}
              >
                <Acc1 />
                <h2 style={{ "padding-left": 10 }}>
                  Generated $129M in incremental sales
                </h2>

                <Acc2 />
                <h2 style={{ "padding-left": 10 }}>
                  annually for a Leading National Retailer.
                </h2>
              </div>

              <h2>Exploratory Data Analysis</h2>
              <p>
                Establish (or re-establish) an understanding of your customer
                base. More importantly, determine where opportunity exists and
                how to capture it. Our EDA engagements quickly profile your
                customers, identify when, what and why they engage and provide a
                marketing roadmap to convert newfound opportunities.
              </p>

              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "flex-wrap": "nowrap",
                  "align-content": "space-around",
                }}
              >
                <Acc3 />
                <h2 style={{ "padding-left": 10 }}>
                  Identified {">"}$100M of new marketing opportunities in {"<"}2
                  months for a Leading National Retailer.
                </h2>
              </div>
            </div>

            <div className="acc-features-end">
              <h2>Next-Gen SMS Marketing</h2>
              <p>
                Ready to take your 1:1 SMS program to the next level? Our
                platform leverages proprietary natural language processing to
                improve customer engagement and results. We deploy upwards of
                25M texts per day.
              </p>

              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "flex-wrap": "nowrap",
                  "align-content": "space-around",
                }}
              >
                <Acc4 />
                <h2 style={{ "padding-left": 10 }}>
                Converted {">"}67% incremental lift over industry standards in a single campaign for a national survey organization.
                </h2>
              </div>

              <h2>Connected Customer Experience</h2>
              <p>
                Your physical locations are marketing channels, too. Leverage
                your customer data in-store to drive a better customer
                experience and incremental sales. Bridgetree connects 1P data
                with on-premise technology to deliver results.
              </p>

              <div
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "flex-wrap": "nowrap",
                  "align-content": "space-around",
                }}
              >
                <Acc5 />
                <h2 style={{ "padding-left": 10 }}>
                  Designed the bank of the future for a Fortune 50 Bank to
                  enable personalized marketing in-branch.
                </h2>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
};

export default AcceleratedSol;
