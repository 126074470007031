import React from "react";
import "./Solution.css";
import lift from "../../images/Asset-1.png";
import solution1 from "../../images/solutions1.png";
import ReactPlayer from 'react-player/youtube';
import MainSolution from "../Solution_Main/MainSolution"
import Footer from "../Footer/Footer";

const Lift = () => {

  const lift_URL="https://www.youtube.com/watch?v=j7eFJjO_fuk&t=1s";
  return (
    <>
    <MainSolution/>
      <div className="solution-feature container">
        <img src={lift} alt="Lift" />
        <h1>DELIVER A MODERN, FULL-SERVICE CRM SOLUTION</h1>
        <p>
          LIFT is our full-service customer data platform (CDP) offering that
          delivers effective consumer marketing at scale. It combines all of
          Bridgetree’s service expertise with powerful data assets and
          technology stack to unlock personalized, 1:1 marketing that consumers
          demand. If your organization wants to deliver personalized marketing
          across every consumer touchpoint while maintaining a single view of
          your customers, learn more about how LIFT can transform your marketing
          results!
        </p>

        <div className="solution-features-Benfites">
          <h1>FEATURES + BENEFITS</h1>

          <div className="features-main">
            <div className="features-left">
              <h2>Marketing Strategy and Services</h2>
              <p>
                We help define the programs, execute the strategy and are
                accountable for the results, end-to-end.
              </p>
              <h2>Customer Data Management and Identity Resolution</h2>
              <p>
                We manage your first-party data, enrich with third and
                second-party data, convert anonymous to known records, and
                persist complete customer profiles over time to maintain
                pristine Golden Records.
              </p>
              <h2>Robust Customer Data Platform (CDP)</h2>
              <p>Robust Customer Data Platform (CDP)</p>
              <h2>Data Science and Machine Learning</h2>
              <p>
                Our team of data scientists identifies opportunities and
                leverage LIFT’s robust model-building capabilities to deliver
                incremental returns.
              </p>
            </div>

            <div className="features-mid">
              <img src={solution1} alt="" />
            </div>

            <div className="features-end">
              <h2>Any Channel Orchestration and Deployment</h2>
              <p>
                We execute multi-step, multi-touch campaigns that can deploy
                across any channel to reach consumers with the right marketing
                at the right time.
              </p>
              <h2>Closed-loop Measurement and Reporting</h2>
              <p>
                We measure the outcomes and key metrics of your marketing with
                an emphasis on incremental returns.
              </p>
              <h2>Open Garden Architecture</h2>
              <p>
                All of our technology can be easily integrated with your
                existing martech and adtech platforms, providing clients options
                to complement, challenge, or replace existing systems and
                processes.
              </p>
            </div>
          </div>
        </div>

        <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={lift_URL}
          controls='true'
          
        />
      </div>

      </div>
      <Footer/>
    </>
  );
};

export default Lift;
