import React from "react";
import "./AboutUs.css";
import Logo from "../../images/White-Bridgetree-Horizontal_hi res.png";
const About = () => {
  return (
    <>
      <div className="about-banner">
        <div className="about-banner-txt container">
          <div className="about-banner-left">
            <h1 className="aboutus-title">About Us</h1>

            <img src={Logo} className="img" alt="Logo" />
            {/* <Logo className="img"/> */}
          </div>

          <div className="about-banner-right">
            <p>
              Bridgetree was founded in 1995 as WIT Development, Inc by Mark
              Beck and Charles Albright with a motto to “make marketing work
              better”. Early on, the company provided USPS logistics for initial
              clients R.J. Reynolds, Thomasville Furniture and US Airways. In
              1997, Mark Beck renamed it to Bridgetree, Inc. and added
              data-mining, data production and consumer databases to the
              company’s list of services. The company opened Bridgetree Research
              Services in 2000 in India and added data gathering and web
              programming. The company opened a second delivery center in 2010
              in India and added mobile application programming operation as a
              capability.
              <br />
              <br />
              In all these years, we have acquired specialized experience in
              gathering and integrating data for leading companies in
              telecommunications, financial services, consumer products,
              publishing, sports entertainment and retail. We’re managing huge,
              interactive customer communications and promotions, fueling
              lookups from databases and providing the common ground needed for
              large companies to partner with us. Our company is at the
              forefront of innovation. We have a strong bias in favor of action
              — we do things, we create movement, and we’re constantly trying
              new things. We are experienced in managing international teams and
              operations. Our analysts, data scientists, developers, and
              business consultants make it a mission to work efficiently and use
              innovation to provide the benefits of low cost and very high
              quality to clients.
              <br />
              <br />
              <strong>
                In a single sentence, Bridgetree means high quality, rapid
                development, innovative programs — all at very good ROI.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
