import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import contact from "../images/Contact.svg";
import "../Styles/Connect.css";
import Footer from "../components/Footer/Footer";

const LetsConnect = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20851893",
          formId: "f67da915-03dc-426d-aa73-3c942231d916",
          target: "#hubspotForm",
        });
      }
    });
  }, [window.hbspt]);

  return (
    <>
      <div className="connect-background-img ">
        <div className="connect-background-img-txt container ">
          <div className="connect-background-img-left">
            <h1>Contact Us</h1>
            <p>Get in Touch</p>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <img className="connect-backgrounc-img-right" src={contact} />
          </div>
        </div>
      </div>
      <div className="contact-page container">
        <p>
          To learn more about our services or to discuss your needs, please
          complete the form below.
        </p>

        <Row className="lets-connect-main">
          <Col xs={12} md={12} sm={12} lg={6}>
            <div className="connectForm">
              <div id="hubspotForm"></div>
            </div>
          </Col>
          <Col lg={5} md={9} sm={11} xs={11} className="connect-img">
            <div className="connect-img-location1">
              <h1>Head Office</h1>
              <p style={{ width: 98 }}>P.O. Box 69 Fort Mill, SC 29716</p>
            </div>
            <div className="connect-img-location2">
              <h1>India Office</h1>
              <p style={{ width: 150, marginBottom:"20px" }}>
                4th Floor, Vayu Block Salarpuria GR Tech Park Whitefield,
                Bangalore India: 560066
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </>
  );
};

export default LetsConnect;
