import React from "react";
import "./Solution.css";
import custom1 from "../../images/custom1.png";
import custom2 from "../../images/custom2.png";
import Footer from "../Footer/Footer";


const CustomSol = () => {
  return (
    <>
   
      <div className="custom-solution container">
        <span>CUSTOM SOLUTIONS</span>
        <p>
          We understand that sometimes our clients need Bridgetree to solve a
          unique problem or deliver an ad hoc project. We are happy to evaluate
          and scope these solutions as project-based or retainer-based models,
          which may span across our core capabilities:
        </p>

        <div className="custom-solution-feature">
          <div className="custom-solution-feature-1">
            <h2>Marketing Strategy </h2>
            <h2>Services</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Customer Data </h2>
            <h2>Management Services</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Data Sourcing and Hygiene)</h2>
            <h2> Services (RightSourcingTM</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Advanced Analytics and </h2>
            <h2>Modeling Services</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Campaign Design and </h2>
            <h2>Execution Services</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Campaign Reporting and </h2>
            <h2>Measurement Services</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Custom Application </h2>
            <h2>Development Services</h2>
          </div>
        </div>

        <h1>DIGITAL CAMPAIGN SERVICES</h1>

        <div className="Digital-service">
            <div className="Digital-service-1">
          <p>
            We offer 100% in-house, white glove development and management of
            online advertising campaigns. Whether you want to use us for one-off
            campaigns or to manage an entire channel, we’re here to help you
            optimize media spend. Everything is hands-on keyboard work and not
            farmed out. We don’t believe in “setting and forgetting” it. You can
            expect us to check on platforms and campaigns multiple times a week
            to make sure they’re delivering positive ROAS.
           <br/>
           <br/>
             No minimum spends!
            Yes, you heard us right. Many agencies buy expensive seats on things
            like demand-side platforms (DSPs) and then pass on the cost to you,
            the client, by asking for uncomfortable monthly minimum spends.
            Spend as little or as much as you want.
          </p>
          
          <p>We help manage and optimize:</p>
      

        <div className="custom-solution-feature">
          <div className="custom-solution-feature-1">
            <h2>Paid search (Google Ads </h2>
            <h2> and Bing Ads)</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Programmatic display and video,  </h2>
            <h2>plus CTV and audio streaming</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Social Ads  </h2>
            <h2>(Facebook, Twitter, etc.) </h2>
          </div>
          </div>
          </div>
          <img src={custom1} alt="Img not found" />
          </div>

          <h1>ANALYTICS + ATTRIBUTION</h1>
          <div className="Digital-service">
            <div className="Digital-service-1">
          <p>
          Not confident in your Google Analytics implementation? Is your Google Tag Manager account a mess? Have you heard about things like multi-touch attribution (MTA), but you’re not sure how to implement in a cost-effective manner?
          </p>
         
          <p>We assist with:</p>
        

          <div className="custom-solution-feature">
          <div className="custom-solution-feature-1">
            <h2>Google Analytics   </h2>
            <h2> and Google Tag Manager)</h2>
            <h2>audits, implementations + </h2>
            <h2>enhancements</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Multi-Touch   </h2>
            <h2>Attribution (MTA)</h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Automated Media or   </h2>
            <h2>Marketing Mix Modeling (MMM) </h2>
          </div>
          <div className="custom-solution-feature-1">
            <h2>Unified Marketing </h2>
            <h2>Measurement (a </h2>
            <h2>synergy between MTA</h2>
            <h2>and MMM)</h2>
          </div>
          </div>

          </div>
          <img src={custom2} alt="Img not found" />
      </div>
      </div>
      <Footer/>
    </>
  );
};

export default CustomSol;
