import React, { useState } from "react";
import "../Styles/NavBar.css";
import { ReactComponent as BtLogo } from "../images/btLogo.svg";
import { useNavigate } from "react-router";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "@mui/icons-material/Menu";
import btLogo from "../images/bridgetree-logo-large.png";

const NavBarItem = () => {
  const navigate = useNavigate();

  
  const handleShow = () => setShow(true);

  const [show, setShow] = useState(false);

  const offsetValue = -56;

  const toggleOffCanvas = () => {
    setShow((show) => !show);
  };

  const toggleoff = () => {
    setShow(false);
  };
  return (
    <>
      {["false", "md"].map((expand, key) => (
        <div className="navbar-item content-wrapper" key={key}>
          <Navbar
            key={expand}
            expand={expand}
            fixed="top"
            style={{
              height: "70px",
              background: "#ffff",
              "box-shadow": " 0px 3px 6px #00000029",
            }}
          >
            <Container className="wrapp">
              <Navbar.Brand>
                
                <img src={btLogo} alt="logo" className="logo" onClick={()=>{navigate("/");}}/>
              </Navbar.Brand>
              <Navbar.Toggle
                onClick={handleShow}
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              >
                <MenuIcon />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                show={show}
                onHide={toggleOffCanvas}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 menu-style">
                     <Nav.Link
                      className="nav-header"
                      onClick={() => {
                        navigate("/whatwedo");
                        toggleoff();
                      }}
                    >
                      WHAT WE DO
                    </Nav.Link>
                    <Nav.Link
                      className="nav-header"
                      onClick={() => {
                        navigate("/teams");
                        toggleoff();
                      }}
                    >
                     ABOUT US
                    </Nav.Link>
                    <Nav.Link
                      className="nav-header"
                      onClick={() => {
                        navigate("/privacy");
                        toggleoff();
                      }}
                    >
                      PRIVACY
                    </Nav.Link>
                    <Nav.Link
                      className="nav-header"
                      onClick={() => {
                        navigate("/careers");
                        toggleoff();
                      }}
                    >
                      CAREERS
                    </Nav.Link>
                    <div style={{ display: "flex" }}>
                      <Nav.Link
                        className="connect-btn"
                        aria-label="Close"
                        data-close
                        smooth={true}
                        offset={offsetValue}
                        duration={500}
                        onClick={() => {
                          navigate("/connect");
                          toggleoff();
                        }}
                      >
                        LET'S CONNECT
                      </Nav.Link>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      ))}
    </>
  );
};

export default NavBarItem;
