import NavBarItem from "./NavBar/NavBarItem";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Careers from "./pages/Careers";
import LetsConnect from "./pages/LetsConnect";
import WhoWeAre from "./pages/WhoWeAre";
import WhatWeDo from "./pages/WhatWeDo";
import Idea from './components/Solution_Main/Idea';
import Symbio from './components/Solution_Main/Symbio';
import Lift from './components/Solution_Main/Lift';
import CustomSol from './components/Solution_Main/CustomSol';
import AcceleratedSol from './components/Solution_Main/AcceleratedSol';
import OurTeams from "./pages/OurTeams";
import SingleCareer from "./components/Career/SingleCareer";
import { useEffect } from "react";
import GoogleAnalytics from './GoogleAnalytics';

function App() {

  useEffect(() => {
    fetch("./config.json").then((response) =>
      response.json().then((data) => {
        localStorage.setItem("url", data.REACT_APP_API_ENDPOINT);
      })
    );
  }, []);
  return (
    <BrowserRouter>
      <GoogleAnalytics/>
      <NavBarItem />
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/privacy" exact element={<Privacy/>} />
        <Route path="/careers" exact element={<Careers/>} />
        <Route path="/careers/:id" exact element={<SingleCareer/>} />
        <Route path="/connect" exact element={<LetsConnect/>} />
        <Route path="/whatwedo" exact element={<WhatWeDo/>} />
        <Route path="/aboutus/whoweare" exact element={<WhoWeAre/>} />
        <Route path="/teams" exact element={<OurTeams/>} />
        <Route path="/solutions/idea-lab" exact element={<Idea/>} />
        <Route path="/solutions/symbio" exact element={<Symbio/>} />
        <Route path="/solutions/lift" exact element={<Lift/>} />
        <Route path="/solutions/custom-solution" exact element={<CustomSol/>} />
        <Route path="/solutions/accelerated-solution" exact element={<AcceleratedSol/>} />
      </Routes>
    </BrowserRouter>   
  );
}

export default App;
