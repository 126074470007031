import React from "react";
import team1 from "../images/a-min.jpg";
import team2 from "../images/b-min.jpg";
import team3 from "../images/c-min.jpg";
import team4 from "../images/d-min.jpg";
import team5 from "../images/JW.jpg";
import "../Styles/Team.css";
import sas from "../images/SAS_partner.png";
import microsoft from "../images/Gold-Microsoft-Partner.png";
import Footer from "../components/Footer/Footer";
import About from "../components/About/About";

const OurTeams = () => {
  return (
    <>
    <About/>
      <div className="team-details container">
        <h1>Leadership</h1>

        <div className="team-card">
          <div className="single-team-card">
            <img src={team1} alt="CHRIS TALLEY" />
            <h1>CHRIS TALLEY</h1>
            <h2>Chief Executive Officer</h2>
            <p>
              MBA, Wake Forest University – 2006 B.A. Communication Studies,<br/>
              University of North Carolina at Greensboro – 1996
            </p>
          </div>
          <div className="single-team-card">
            <img src={team5} alt="JORDAN WESLEY" />
            <h1>JORDAN WESLEY</h1>
            <h2>Vice President, Sales{` & `}Marketing</h2>
            <p>
            MBA, Wake Forest University,<br/>
            BS, Washington & Lee University
            </p>
          </div>
          <div className="single-team-card">
            <img src={team2} alt="MARK MALONE" />
            
            <h1>MARK MALONE</h1>
            <h2>Vice President, Sales{` & `}Business Development</h2>
            <p>B.B.A. Management, Georgia State University – 1993</p>
           
          </div>
          <div className="single-team-card">
            <img src={team3} alt="SANJAY MAMANI" />
            <h1>SANJAY MAMANI</h1>
            <h2>Vice President, Technology</h2>
            <p>
              MS, Information Technology{` & `}Management – UNCG – 2007<br/>
              Bachelor’s in Engineering, Industrial & Production – GIT, India –
              1996
            </p>
            
          </div>
          <div className="single-team-card">
            <img src={team4} alt="SONNA HUGHES" />
            <h1>SONNA HUGHES</h1>
            <h2>Chief Financial Officer</h2>
            <p>
              Bachelor Business Administration- Accounting, Mississippi State
              University
            </p>
          </div>
        </div>

        <div className="sol-location">
          <div className="sol-location-right">
            <h1>Locations</h1>
            <div className="sol-location-banner-right">
              <div className="head-ofice">
                <h1>Head Office</h1>
                <span>
                  P.O. Box 69 <br />
                  Fort Mill,
                  <br /> SC 29716
                </span>
              </div>
              <div className="india-office">
                <h1>India Office</h1>
                <span>
                  4th Floor, Vayu Block <br /> Salarpuria GR Tech Park <br />{" "}
                  Whitefield, Bangalore <br /> India: 560066
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurTeams;
