import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import Footer from "../Footer/Footer";
import ApiService from "../../services/ApiService";
import { ReactComponent as Joinus } from "../../images/joinOurTeam.svg";
import { Markup } from "interweave";

const SingleCareer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [careerDetail, setCareerDetail] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const abortCont = new AbortController();
    setTimeout(() => {
      ApiService.getCareerId(id)
        .then((res) => {
          setLoading(false);
          setCareerDetail(res.data.data);
        })
        .catch((err) => console.log(err));
    }, 200);
  }, []);


  return (
    <>
      {loading ? (
        <CircularProgress className="loading-progress" />
      ) : (
        <>
          <div className="carrer-img-banner">
            <div className="carrer-banner-txt">
              <div className="carrer-banner-txt-left">
                <h2>Careers</h2>
                <div>
                  <h1>Join Our Team</h1>
                  <p>
                    We’re always looking for qualified candidates to join the
                    Bridgetree family. If you’d like to be a part of our team,
                    look through the job listings below and submit your resume
                    to the listed email address.
                  </p>
                </div>
              </div>

             
              <div style={{ displya: "flex", justifyContent: "center" }}>
                <Joinus className="carrer-banner-txt-right" />
              </div>
            </div>
          </div>
          <div className="single-career container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>{careerDetail.title}</h1>
            </div>

            <div className="single-career-top-main">
              <div className="single-career-top">
                <h2>Locations</h2>
                <span>{careerDetail.location}</span>
              </div>
             
              <div className="single-career-top">
                <h2>Experience Required</h2>
                <span>{careerDetail.experience} years</span>
              </div>
              <div className="single-career-top">
                <h2>Role</h2>
                <span>{careerDetail.projectRole}</span>
              </div>
              
              <div className="single-career-top">
                <h2>Primary Skills</h2>
                <span>{careerDetail.primarySkillSet}</span>
              </div>
              <div className="single-career-top">
                <h2>Seccondary Skills</h2>
                <span>{careerDetail.secondarySkillSet}</span>
              </div>
            </div>

            <div>
                <h2 className="single-career-middle-heading">Qualifications</h2>
                <p className="single-career-middle">
                  {careerDetail.qualification}
                </p>
              </div>
           

            <div className="single-career-end">
            <div>
              <h2 className="single-career-middle-heading">JOB DESCRIPTION</h2>
              <p className="single-career-middle">
                <Markup content={careerDetail.jobDescription} />
              </p>
            </div>
              <div>
                <h2 className="single-career-middle-heading">
                  RESPONSIBILITIES
                </h2>
                <p className="single-career-middle">
                  
                  <Markup content= {careerDetail.roleAndResponsibility} />    

                </p>
              </div>
              
            </div>

            <div
              style={{
                display: "flex",
                justifContent: "flex-end",
                "justify-content": "flex-end",
              }}
            >
              <a
                href={`mailto:HR@bridgetree.com?subject=Job Application for Role - ${careerDetail.projectRole}`}
                className="apply-btn"
              >
                Apply Now
              </a>
             
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default SingleCareer;
